import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import Aside from "../components/aside";
import { Link } from "gatsby";
import Seo from "../components/seo";

export const Head = () => {
  return (
    <Seo
      title="コラム一覧"
      description="鎌田鍼灸整骨院がお届けする、コラムの一覧ページです"
    />
  );
};

const ArticleList = ({ data, pageContext }) => {
  const edges = data.nanoCms.articles.edges;
  edges.map((edge) => edge.node);
  const { currentPage, numPages } = pageContext;
  const prevPageLink =
    currentPage - 1 === 1 ? "/pages" : `/pages/${currentPage - 1}`;
  const nextPageLink = `/pages/${currentPage + 1}`;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;

  const navLinks = [
    {
      id: 1,
      name: "トップ",
      link: "/",
      dropdown: false,
    },
    {
      id: 2,
      name: "鍼灸メニュー",
      link: "/menu",
      dropdown: false,
    },
    {
      id: 3,
      name: "コラム一覧",
      link: "/pages",
      dropdown: false,
    },
    {
      id: 4,
      name: "各店舗",
      dropdown: true,
      subItems: [
        { name: "鎌田鍼灸整骨院", link: "/" },
        { name: "守恒鍼灸美容館", link: "/biyoukan" },
        { name: "一花堂", link: "/ikkadou" },
      ],
    },
    {
      id: 5,
      name: "アクセス・お問い合わせ",
      link: "/#storelist",
      dropdown: false,
    },
  ];

  return (
    <Layout as="article" navLinks={navLinks} color={"rgb(64, 155, 57, 0.2)"}>
      <main>
        <div className="image-heading">
          <StaticImage
            src="../images/post-kamata.png"
            width={4000}
            alt="topの画像"
          />
        </div>
        <div className="cntWrapper">
          <div className="cntWrapperIndex">
            <div className="mainnav">
              <section>
                {data.nanoCms.articles.edges.map(({ node }) => (
                  <article className="articleItem">
                    <div className="articleLeft">
                      <Link to={`/articles/${node.url}`}>
                        {node.ogpImage ? (
                          <img
                            src={node.ogpImage}
                            alt="ogp"
                            className="aside-content-left-picture"
                          />
                        ) : (
                          <StaticImage
                            src="../images/nanocms.png"
                            alt="topの画像"
                            className="aside-content-left-picture"
                          />
                        )}
                      </Link>
                    </div>
                    <div className="articleRight">
                      <Link to={`/articles/${node.url}`}>
                        <h2>{node.title}</h2>
                      </Link>
                      <p className="articleRightSubtitle">{node.description}</p>
                      <div className="articleRightDate">
                        <span>
                          <time>{node.updatedAt}</time>
                        </span>
                      </div>
                    </div>
                  </article>
                ))}
              </section>
              {/* Pagination Links */}
              <div className="pagination-box">
                {numPages > 3 && (
                  <Link
                    to="/pages"
                    className="btn pagination-btn-side me-1 rounded-30"
                  >
                    最初
                  </Link>
                )}
                {!isFirst && (
                  <Link
                    to={prevPageLink}
                    className="btn pagination-btn-side me-1 rounded-30"
                  >
                    前へ
                  </Link>
                )}

                {Array.from({ length: numPages }, (_, i) => i + 1).map(
                  (pageNumber) => (
                    <Link
                      key={`pagination-number${pageNumber}`}
                      to={pageNumber === 1 ? "/pages" : `/pages/${pageNumber}`}
                      className={
                        pageNumber === currentPage
                          ? "btn pagination-btn active me-1 rounded-30"
                          : "btn pagination-btn me-1 rounded-30"
                      }
                    >
                      {pageNumber}
                    </Link>
                  )
                )}

                {!isLast && (
                  <Link
                    to={nextPageLink}
                    className="btn pagination-btn-side me-1 rounded-30"
                  >
                    次へ
                  </Link>
                )}
                {numPages > 3 && (
                  <Link
                    to={`/pages/${numPages}`}
                    className="btn pagination-btn-side me-1 rounded-30"
                  >
                    最後
                  </Link>
                )}
              </div>
            </div>
            <Aside />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default ArticleList;

export const query = graphql`
  query ($limit: Int, $startKey: Int) {
    nanoCms {
      articles(
        isPublished: true
        category: "not お知らせ"
        limit: $limit
        startKey: $startKey
      ) {
        edges {
          node {
            title
            url
            description
            ogpImage
            updatedAt
            timestamp
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
